import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';

import { QUERY_DATA } from '@/types/interfaces';
import { useApp } from '@/components/App';
interface PROPS {
  title?: string;
  description?: string;
  keyword?: string;
  siteUrl?: string;
  locale?: string;
  ogTitle?: string;
  ogType?: string;
  ogUrl?: string;
  ogDescription?: string;
  siteName?: string;
}
const categoryData = ['escorts'];

const getBreadcrumb = (tag: PROPS, cat: string, state: string) => {
  if (tag.title === 'Search Ads') {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': 'https://247torax.com/',
            name: 'Home',
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `https://247torax.com/${cat}/`,
            name: 'Escorts & Call Girls Services',
          },
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': `https://247torax.com/${cat}/${state}`,
            name: `Call Girls in ${state}`,
          },
        },
      ],
    };
  } else {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'https://247torax.com/',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://247torax.com/?s={247torax}',
        'query-input': 'required name=247torax',
      },
    };
  }
};

const getMetaTags = (metaTag: { titleTag: string; descriptionTag: string; keywordTag: string }) => {
  const metaData = { titleTag: '', keywordTag: '', descriptionTag: '' };
  metaData['titleTag'] = metaTag?.titleTag || 'Verified Call Girls & Escort services in india | With Photo & Contact';
  metaData['keywordTag'] = metaTag?.keywordTag || '';
  metaData['descriptionTag'] =
    metaTag?.descriptionTag ||
    'Get Verified Call girls & Escort service at your fingertip with us. Get All list of top call girls with Photo and contact details from Here. Browse Now';
  return metaData;
};

const Meta = (props: PROPS) => {
  const router = useRouter();
  const { state } = useApp();
  const {
    ogDescription = 'Description',
    locale = '',
    ogTitle = '',
    ogType = '',
    ogUrl = '',
    siteName = '247torax',
  } = props;
  const queryData: QUERY_DATA = useMemo(() => {
    if (router.query && router.query.params) {
      const { params } = router.query;

      const [category, state, city]: any = params;
      if (categoryData.includes(category)) {
        return {
          category,
          state,
          city,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.params]);

  const { titleTag, descriptionTag, keywordTag } = getMetaTags(state?.meta);

  const breadcrumbJsonLd = getBreadcrumb(props, queryData?.category, queryData.state);

  const homePage = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: titleTag,
    description: descriptionTag,
    url: 'https://247torax.com' + router.asPath,
  };

  return (
    <Head>
      <title>{titleTag}</title>
      <meta name="title" content={titleTag} />
      <meta name="description" content={descriptionTag} />
      <meta name="keyword" content={keywordTag} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/assets/favicon/favicon.ico" />
      <link rel="canonical" href={'https://247torax.com' + router.asPath} />
      <meta property="og:image" content="/assets/img/logo.webp" />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:site_name" content={siteName} />

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(homePage) }}></script>
    </Head>
  );
};

export default memo(Meta);
